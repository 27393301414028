// modules
import posed from 'react-pose'
import PropTypes from 'prop-types'
import React from 'react'
// styles
import styles from './BurgerButton.module.scss'
// animations
import { animations } from './BurgerButtonAnimations.js'

// definitions
const TopBread = posed.div(animations.TopBread)
const BotBread = posed.div(animations.BotBread)

function BurgerButton({ clickHandler, isActive }) {
  return (
    <button
      className={styles.BurgerButton}
      onClick={clickHandler}
      aria-label="toggle navigation"
    >
      <TopBread className={styles.bread} pose={isActive ? 'cross' : 'burger'} />
      <BotBread className={styles.bread} pose={isActive ? 'cross' : 'burger'} />
    </button>
  )
}

// props default
BurgerButton.defaultProps = {
  clickHandler: null,
  isActive: false,
}

// props type checking
BurgerButton.propTypes = {
  clickHandler: PropTypes.func,
  isActive: PropTypes.bool,
}

// exports
export default BurgerButton
