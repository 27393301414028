const transitions = {
  bread: {
    type: 'spring',
    stiffness: 1000,
    damping: 25,
    mass: 1,
  },
}

export const animations = {
  // Top Bread
  TopBread: {
    burger: {
      rotate: '0deg',
      y: '-30%',
      transition: transitions.bread,
    },
    cross: {
      rotate: '-45deg',
      y: '0%',
      transition: transitions.bread,
    },
  },
  // Bottom Bread
  BotBread: {
    burger: {
      rotate: '0deg',
      y: '30%',
      transition: transitions.bread,
    },
    cross: {
      rotate: '45deg',
      y: '0%',
      transition: transitions.bread,
    },
  },
}
